var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"side-navigation-menu",class:{ 'is-open': _vm.isCollapse },on:{"mouseenter":_vm.expandSidebar,"mouseleave":_vm.collapseSidebar}},[_c('el-scrollbar',[_c('el-menu',{ref:"sidebarMenu",class:{
        'default-style': !_vm.brandingInfo.styled_sidemenu,
        'inverted-color-style': _vm.brandingInfo.styled_sidemenu,
      },attrs:{"default-active":_vm.activeLink,"collapse":!_vm.isCollapse,"active-text-color":"#F754A2"}},[_vm._l((_vm.menu),function(sideBarItem,i){return [(sideBarItem.children && sideBarItem.children.length)?[_c('el-submenu',{key:sideBarItem._id,attrs:{"index":sideBarItem._id}},[_c('template',{slot:"title"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{staticClass:"submenu",attrs:{"title":sideBarItem.title.length > 15 ? sideBarItem.title : ''}},[_vm._v(" "+_vm._s(_vm._f("truncate")(sideBarItem.title,15, "..."))+" ")])],1),_c('el-menu-item-group',[_vm._l((sideBarItem.children),function(sideBarSubItem){return [(sideBarSubItem.type == 'Child')?[(_vm.checkPermissionsStatus(sideBarSubItem))?_c('el-menu-item',{key:sideBarSubItem._id,staticClass:"custom-icon",attrs:{"index":sideBarSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubItem, true)}}},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{staticClass:"menu-title",attrs:{"title":sideBarSubItem.title.length > 11
                          ? sideBarSubItem.title
                          : ''}},[_vm._v(" "+_vm._s(_vm._f("truncate")(sideBarSubItem.title,11, "..."))+" ")])],1):_vm._e()]:_vm._e(),(sideBarSubItem.type == 'SubParent')?[_c('el-submenu',{key:sideBarSubItem._id,staticClass:"custom-icon",attrs:{"index":sideBarSubItem._id}},[_c('template',{slot:"title"},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarSubItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{staticClass:"menu-title",attrs:{"title":sideBarSubItem.title.length > 10
                            ? sideBarSubItem.title
                            : ''}},[_vm._v(_vm._s(_vm._f("truncate")(sideBarSubItem.title,10, "...")))])],1),_c('el-menu-item-group',[_vm._l((sideBarSubItem.children),function(sideBarSubSubItem){return [(_vm.checkPermissionsStatus(sideBarSubSubItem))?_c('el-menu-item',{key:sideBarSubSubItem._id,staticClass:"custom-icon",attrs:{"index":sideBarSubSubItem._id},on:{"click":function($event){return _vm.routeClick(sideBarSubSubItem, true)}}},[(
                              _vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon
                            )?_c('icons',{attrs:{"iconName":sideBarSubSubItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{staticClass:"menu-title",attrs:{"title":sideBarSubSubItem.title.length > 8
                                ? sideBarSubSubItem.title
                                : ''}},[_vm._v(" "+_vm._s(_vm._f("truncate")(sideBarSubSubItem.title,8, "..."))+" ")])],1):_vm._e()]})],2)],2)]:_vm._e()]})],2)],2)]:[(_vm.checkPermissionsStatus(sideBarItem))?_c('el-menu-item',{key:i + sideBarItem._id,staticClass:"custom-icon",attrs:{"index":sideBarItem._id},on:{"click":function($event){return _vm.routeClick(sideBarItem, false)}}},[(_vm.getCompanyDetails && _vm.getCompanyDetails.menuIcon)?_c('icons',{attrs:{"iconName":sideBarItem.icon}}):_vm._e(),_vm._v("   "),_c('span',{staticClass:"menu-title",attrs:{"title":sideBarItem.title.length > 16 ? sideBarItem.title : ''}},[_vm._v(_vm._s(_vm._f("truncate")(sideBarItem.title,16, "...")))])],1):_vm._e()]]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }